html {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Ubuntu", sans-serif;
  background-color: #EEF0EB;
  overflow-x: hidden;
  overflow-y: hidden;
}

@media screen and (max-width: 575.98px) {
  body {
    font-size: 17px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  body {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 17px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body {
    font-size: 17px;
  }
}

@media (min-width: 1200px) {
  body {
    font-size: 21px;
  }
}

/* BUTTONS */

.btn {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 75px;
  font-size: 1.618em;
  font-weight: 700;
  border-bottom: solid 3px #84042C;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 10px -2px rgba(74, 3, 26, 0.75);
  -moz-box-shadow: 0px 5px 10px -2px rgba(74, 3, 26, 0.75);
  box-shadow: 0px 5px 10px -2px rgba(74, 3, 26, 0.75);
}

@media (min-width: 768px) {
  .btn {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media screen and (max-width: 767.98px) {
  .btn {
    width: 100%;
  }
}

.btn-primary {
  color: #FFF;
  background-color: #D11F57;
}

.btn-primary:hover {
  background-color: #FF266A;
  border-bottom: solid 3px #D11F57;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #FF266A;
  border-color: #D11F57;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.2);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 575.98px) {
  .navbar {
    padding: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .navbar {
    padding-left: 25px;
    padding-right: 40px;
    padding-top: 25px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar {
    padding-left: 25px;
    padding-right: 50px;
    padding-top: 25px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar {
    padding-left: 45px;
    padding-right: 70px;
    padding-top: 45px;
  }
}

@media (min-width: 1200px) {
  .navbar {
    padding-left: 45px;
    padding-right: 70px;
    padding-top: 45px;
  }
}

.navbar a {
  margin-left: 30px;
  color: #fff;
}

.navbar a:hover {
  color: #fff;
}

.navbar a.nav-logo {
  margin: 0;
}

.slaid {
  height: 100vh;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.container-fluid {
  height: 100%;
  z-index: 2;
}

.slaid .container-fluid .row {
  height: 100%;
}

.cta {
  position: absolute;
  text-align: center;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 3;
}

@media screen and (max-width: 575.98px) {
  .cta {
    bottom: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .cta {
    bottom: 25px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .cta {
    bottom: 25px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .cta {
    bottom: 45px;
  }
}

@media (min-width: 1200px) {
  .cta {
    bottom: 45px;
  }
}

h3 {
  font-size: 1.618em;
  font-weight: 700;
  margin: 0;
  line-height: 100%;
  color: #000;
}

@media (min-width: 768px) {
  h3 {
    font-size: 2.617em;
  }
}

h2 {
  font-size: 2.617em;
  font-weight: 700;
  margin-top: 0;
  line-height: 100%;
  margin-bottom: 0.8em;
  text-transform: uppercase;
  color: #fff;
}

@media (min-width: 768px) {
  h2 {
    font-size: 6.853em;
  }
}

.collapsing,
.collapse.show {
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(209, 31, 87, 0.9);
  z-index: -1;
  transition: all 0.3s;
}

.collapsing {
  opacity: 0;
}

.collapse.show {
  opacity: 1;
}

.collapsing .navbar-nav,
.collapse.show .navbar-nav {
  height: 100vh;
  text-align: center;
  padding-right: 30px;
  font-size: 2.617em;
}

.hex1,
.hex2,
.hex3 {
  position: absolute;
  z-index: 1;
}

#slaid1 .hex1 {
  left: 20vw;
  top: 5vh;
}

#slaid1 .hex2 {
  left: 30vw;
  top: 10vh;
}

#slaid1 .hex3 {
  right: 20vw;
  bottom: 5vh;
}

#slaid2 .hex1 {
  right: 10vw;
  top: 2vh;
}

#slaid2 .hex2 {
  left: 30vw;
  top: 10vh;
}

#slaid2 .hex3 {
  left: 20vw;
  bottom: 5vh;
}

#slaid3 .hex1 {
  left: 20vw;
  bottom: 5vh;
}

#slaid3 .hex2 {
  right: 30vw;
  top: 20vh;
}

#slaid3 .hex3 {
  right: 10vw;
  bottom: 10vh;
}

#slaid4 .hex1 {
  right: 30vw;
  top: 2vh;
}

#slaid4 .hex2 {
  right: 30vw;
  bottom: 10vh;
}

#slaid4 .hex3 {
  left: 10vw;
  bottom: 10vh;
}

#slaid5 .hex1 {
  left: 20vw;
  top: 5vh;
}

#slaid5 .hex2 {
  left: 30vw;
  top: 10vh;
}

#slaid5 .hex3 {
  right: 20vw;
  bottom: 5vh;
}

#slaid6 .hex1 {
  right: 10vw;
  top: 2vh;
}

#slaid6 .hex2 {
  left: 30vw;
  top: 10vh;
}

#slaid6 .hex3 {
  left: 20vw;
  bottom: 5vh;
}

#slaid7 .hex1 {
  right: 30vw;
  top: 2vh;
}

#slaid7 .hex2 {
  right: 30vw;
  bottom: 10vh;
}

#slaid7 .hex3 {
  left: 10vw;
  bottom: 10vh;
}

#slaid8 .hex1 {
  left: 20vw;
  top: 5vh;
}

#slaid8 .hex2 {
  left: 30vw;
  top: 10vh;
}

#slaid8 .hex3 {
  right: 20vw;
  bottom: 5vh;
}

#slaid1 {
  background-image: url("bg1-mobile.dd184773.jpg");
}

#slaid2 {
  background-image: url("bg2-mobile.413fe48c.jpg");
}

#slaid3 {
  background-image: url("bg3-mobile.783c4ccb.jpg");
}

#slaid4 {
  background-image: url("bg4-mobile.5754a569.jpg");
}

#slaid5 {
  background-image: url("bg5-mobile.ec53912a.jpg");
}

#slaid6 {
  background-image: url("bg6-mobile.de11db19.jpg");
}

#slaid7 {
  background-image: url("bg7-mobile.d716b41e.jpg");
}

#slaid8 {
  background-image: url("bg8-mobile.506b9af0.jpg");
}

@media (min-width: 768px) {
  #slaid1 {
    background-image: url("bg1.045e274d.jpg");
  }

  #slaid2 {
    background-image: url("bg2.d26c6d63.jpg");
  }

  #slaid3 {
    background-image: url("bg3.409b6249.jpg");
  }

  #slaid4 {
    background-image: url("bg4.05b0dfc9.jpg");
  }

  #slaid5 {
    background-image: url("bg5.bc665c4e.jpg");
  }

  #slaid6 {
    background-image: url("bg6.ff231cbc.jpg");
  }

  #slaid7 {
    background-image: url("bg7.95877aab.jpg");
  }

  #slaid8 {
    background-image: url("bg8.a160af4b.jpg");
  }
}
/*# sourceMappingURL=index.93fab50f.css.map */
